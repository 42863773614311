.categoryFooter{
    background-color: black;
    border : 2px solid white;
    border-radius: 15px;
    /* padding: 0; */
    padding-top : 35px;
    margin-top:20px;
    margin-bottom:20px;
    width:80%;
    height: 100px;
    font-size: larger;
    text-align: center;
    vertical-align: middle;

    color:white;
}

.categoryFooter:hover{
 border-color: linear-gradient(rgba(0,0,0,1),rgba(253,92,66,1));
 color: linear-gradient(rgba(255,156,0,1),rgba(253,92,66,1));
}

.footermain{
    color:white;
    background-color: black;
    width:100%;
}

.footerBottom{
    background : linear-gradient(rgb(255,156,0),rgb(253,92,66));
    margin:auto;
    /* border-top-left-radius: 100%;
    border-top-right-radius: 100%; */
    /* border-radius: 50% / 30%  30% 0 0; */
    /* -webkit-border-radius: 50% / 60% 60% 40% 40%; */
    /* color : linear-gradient(rgba(255,156,0,1),rgba(253,92,66,1)); */
    height:500px;
    width : 100%;
}

.footerBottomTitle{
    text-align: center;
    padding-top : 10px;
    /* font-size: xx-large; */
}
.footerBottomMail{
    text-align: center;
    margin:auto;
    margin-top : 20px;
    /* font-size: x-large; */
    padding-top : 10px;
}
.footerBottomSocialMediaID{
    background-color: white;
    /* background-color: rgb(255,156,0); */
    color:rgb(255,156,0);
    border : 10px solid white;
    /* border-radius: 50% 50%; */
    margin:10px;
    font-size: 50px;
    /* padding : px; */
    /* padding: 20px; */
}