.category{
    font-family: var(--font-titillium_web)!important;
    padding-left : 20px;
    width : auto;
    height:180px;
    vertical-align: middle;
    /* margin-top: 15px;
    padding-bottom: 15px; */
    margin-left: 0 auto;
    margin-right: 0 auto;
    border : 4px solid white;
    color : white;
    transition : all 0.75s ease;
    font-weight: bolder;
    vertical-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    /* margin-bottom: 100px; */
    text-decoration: none;
}

.category:hover{
    /* color:rgba(255,156,0,1);
    border-color: rgba(255,156,0,1); */
    color:black;
    
    background-color: white;
}

/* .category > p{ */
    /* position: relative; */
    /* top : 80%; */
    /* font-style: var(--font-comfortaa);
    text-align: center; */
/* } */