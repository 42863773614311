.navigation{
    width:100%;
    height:80px;
}


.navigationBox {
    width:80%;
}

.navigationPics{
    height: 30px;
}