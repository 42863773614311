@tailwind base;
@tailwind components;
@tailwind utilities;

h1,h2,h3,h4,h5 {
  font-family: "Titillium Web";
  color:white;
}

h2{
  font-size : 20px;
}

.btn-secondary {
  background: linear-gradient(to right, rgba(255,156,0,1),rgba(253,92,66,1));
   color : white !important;
}

.archivoBlack{
  font-family: "Archivo Black";
}
.titilliumWeb{
  font-family: "Titillium Web";
}
.comfortaa{
  font-family: "Comfortaa";
}

.btndisplay{
  color:white;
  background: linear-gradient(to right, rgba(255,156,0,1),rgba(253,92,66,1));
  border-radius: 30px;
  padding: 20px;
  padding-right: 20px;
  
}
.btndisplay > a {
  color:white;
  font-weight: bold;
  text-decoration: none;
}

.textLinear{
  /* color:rgba(255,156,0,1); */
  /* font-size: 72px; */
  background: -webkit-linear-gradient(left,rgba(255,156,0,1),rgba(253,92,66,1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
