.modal-xl{
    width:100%;
}
.mantine-Modal-header{
    background-color: none;
    padding:0;
    /* padding:0; */
    /* display:none; */
}
.mantine-Modal-content {
    /* background-color: black; */
    padding:0px;
}
.mantine-Modal-body{
}