.btnActif{
    background-color:rgba(255,156,0,1);
    color:white;
}
.btnFilter{
    border-radius: 30px 30px;
    margin-left:5px;
    margin-right:5px;
    padding-left : 15px;
    padding-right : 15px;
    border:1px solid black;
}
.titre{
    color : black!important;
}