

.panel{
    height:540px;
    position : relative;
    /* transform-origin: bottom; */
    top:0;
}

.pos{
    min-height:540px;
    /* align-items: end; */
    min-width : 100vw;
    /* position : absolute; */
    padding-top:50px;
}

.panelNavigation{
    position:absolute;
    top:0;
}

.cardPanel{
    transform-origin: right;
    transform: rotate('120deg');
}

.pos1{
    background-color: #111111;
    height:100%;
}
.pos2{
    background: linear-gradient(#FF9C00,#FD5C42);
    height:100%;
}
.pos3{
    background: linear-gradient(#FF9C00,#FECA3B);
    height:100%;

}


.poscard1{
    transform: rotate(-25deg);
    /* left:220px; */
    /* position:absolute; */
    /* top:120px; */
    padding-top:120px;
    
}
.poscard2{
    /* position:absolute; */
    /* top:60px; */
    padding-top:60px;
    
}
.poscard3{
    /* position:absolute; */
    transform: rotate(25deg);
    /* right:220px;
    top:120px; */
    padding-top:120px;
}

.maincards1{
    min-height:100%;
    
}
.maincards2{
    min-height:100%;
    
    
}
.maincards3{
    min-height:100%;


}