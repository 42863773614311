#maincards1{
    position : absolute;
    transform-origin: bottom center;
    transform: rotate('120deg');
    /* opacity:0; */
    min-height:600px;
    width:100vw;
    
}
#maincards2{
    opacity:0;
    position : absolute;
    /* transform-origin: top; */
    transform: rotate(120deg);
    min-height:600px;
    width:100vw;
    
    transform-origin: bottom center;
    
}
#maincards3{
    opacity:0;
    position : absolute;
    transform-origin: bottom center;
    min-height:600px;
    width:100vw;
    transform: rotate(240deg);

}
