.jocardsOutterBound{
    background-color: white;
    border: 1px solid black;
    border-radius : 15px; 
    height:auto;
    width:350px;
    
    box-shadow: 2px 2px  2px black;
    z-index: 0;
}

.jocardsOutterLogo{
    width:auto;
    height:20px;
    z-index: 0;
}

.jocardsInnerImage{
    position:relative;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;
    height:80%;
    width:80%;
}

.workersName{
    font-style: var(--font-archivoBlack);
    font-weight: 400;
    color:#ff9c00;
    font-size: 24px;
    width:80%;
    margin:auto;
}

.workersJob{
    width:80%;
    margin:auto;
    font-style: var(--font-archivoBlack);
    font-size:14px

}