.modal-xl{
    width:98%;
}
.mantine-Modal-header{
    background-color: none;
    padding:0;
    /* padding:0; */
    /* display:none; */
}
.mantine-Modal-content {
    background-color: black;
    margin-left:20px;
    margin-right:20px;
}
.mantine-Modal-body{
    padding:0;
}